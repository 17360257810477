<template>
    <head>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
    </head>
    <page-header></page-header>
    <div class="product-container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="searchbox">
                    <div class="product-info">
                        <h2>{{ selectedproduct.name }}</h2>
                        <!-- <button class="add_isexaminable" @click="openExaminableTasksModal()">Add Examinable Tasks</button> -->
                        <button class="importCSV-button" @click="navigateToImportTasks(selectedproduct)">IMPORT
                            TASKS</button>
                        <button class="importCSV-button" @click="navigateToImportB2Tasks(selectedproduct)">IMPORT B2
                            TASKS</button>
                        </div>
                    <div class="filter-section">
                        <div class="filter-heading">Filter</div>
                        <div class="filter-row">
                            <input type="text" placeholder="Activity" class="filter-input" v-model="descriptionFilter">
                            <!-- <input type="text" placeholder="Reference" class="filter-input" v-model="referenceFilter"> -->
                        </div>
                        <div class="filter-row1">
                            <input type="text" placeholder="Task No" class="filter-input" v-model="taskNoFilter">
                            <input type="text" placeholder="AMTOSS" class="filter-input" v-model="scopeFilter">
                            <input type="text" placeholder="Category" class="filter-input" v-model="categoryFilter">
                            <input type="text" placeholder="Area" class="filter-input" v-model="areaFilter">
                            <input type="text" placeholder="Location" class="filter-input" v-model="locationFilter">

                            <!-- <input type="text" placeholder="Est. Time" class="filter-input" v-model="esttimeFilter"> -->
                            <!-- <input type="text" placeholder="Is Exam" class="filter-input" v-model="isExamFilter"> -->
                            <input type="text" placeholder="Chapter No" class="filter-input" v-model="chaptFilter">
                            <button class="clear-button" @click="clearFilters">Clear</button>
                            <!--<button class="filter-button" @click="applyFilters">Filter</button>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pagination-button-container">
            <div class="pagination-container">
                <Pagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage"
                    @page-change="onPageChange" />
            </div>
            <div class="pagination-info">
                Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ filteredTasksLength }} tasks
            </div>
            <!-- <div class="coldropdown">
                <button @click="toggleDropdown" class="dropdownbutton">Show/Hide Columns</button>
                <div class="col-dropdown-menu" :class="{ 'col-dropdown-menu-active': showDropdown }">
                    <div v-for="column in allPossibleColumns" :key="column">
                        <input type="checkbox" :id="column" v-model="selectedColumns" :value="column">
                        <label :for="column">{{ column }}</label>
                    </div>
                    <button @click="saveColumnPreferences" class="prefbtn">Save Preferences</button>
                </div>
            </div> -->
        </div>
        <div class="row">
            <div class="col-12">
                <div class="table-section">
                    <table class="data-table">
                        <thead>
                            <tr>
                                <th class="task-no">Task No</th>
                                <th class="category">Ratings</th>
                                <th class="category">Module</th>
                                <th class="category">ATA Ch</th>
                                <th class="category">Area</th>
                                <th class="category">Location</th>
                                <th class="category">AMTOSS</th>
                                <th class="description">Activity</th>
                                <th class="category">Level</th>
                                <th class="category">Category</th>
                                <th class="edit">Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="task in filteredTasks" :key="task.task_id">
                                <td>{{ task.task_no }}</td>
                                <td>{{ task.ratings }}</td>
                                <td>{{ task.module }}</td>
                                <td>{{ task.chapt_no }}</td>
                                <td>{{ task.area }}</td>
                                <td>{{ task.location }}</td>
                                <td>{{ task.AMTOSS }}</td>
                                <td>{{ task.activity }}</td>
                                <td>{{ task.level }}</td>
                                <td>{{ task.category }}</td>
                                <td>
                                    <i class="fas fa-edit edit-icon" @click="viewEditTask(task.task_id)"></i>
                                </td>
                            </tr>
                            <tr v-if="filteredTasks.length === 0">
          <td colspan="100" style="text-align: center;">No Matching Tasks</td>
        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <edit-task-modal v-if="showEditTask" @close="closeEditTask"></edit-task-modal>
    <import-tasks-modal :show-modal="showImportModal" @modal-closed="fetchTasks" @tasks-imported="handleImportedTasks"
        :product-id="productId" @close="closeImportModal" />
    <ExaminableTasksModal v-if="showExaminableTasksModal" :show-modal="showExaminableTasksModal"
        :next-task-number="nextTaskNumber" @close="closeExaminableTasksModal" />
</template>

<script>
import PageHeader from '@/components/Examiner/Header.vue';
import axios from 'axios';
import './productPage.css';
import Pagination from '../TablePagination.vue';
import EditTaskModal from './EditTaskModal.vue';
import ExaminableTasksModal from './ExaminableTasksModal.vue';

export default {
    name: 'productPage',
    watch: {
        perPage(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.calculateTotalPages();
                this.currentPage = 1;  // reset to the first page whenever perPage changes
            }
          },
        chaptFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    descriptionFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    taskNoFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    scopeFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    manobFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    areaFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    locationFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    categoryFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    },
    components: {
        Pagination,
        PageHeader,
        EditTaskModal,
        ExaminableTasksModal,
    },
    data() {
        return {
            productId: this.$route.params.productId,
            selectedproduct: '', // Holds the selected product
            products: [], // Holds the list of products
            searchQuery: '', // Holds the user's search query
            showImportModal: false,
            showExaminableTasksModal: false,
            tasks: [],
            currentPage: 1,
            perPage: 10,
            showEditTask: false,
            descriptionFilter: '',
            referenceFilter: '',
            taskNoFilter: '',
            scopeFilter: '',
            categoryFilter: '',
            manobFilter: '',
            esttimeFilter: '',
            isExamFilter: '',
            chaptFilter: '',
            areaFilter: '',
            locationFilter: '',
            shouldFilter: false,
            filteredTasksLength: 0,
        };
    },
    async mounted() {
        console.log(this.$store.state.selectedProduct);
        this.fetchproducts(); // Fetch product data
        this.fetchTasks();
    },
    methods: {
        navigateToImportTasks(selectedproduct) {
            this.$router.push({
                name: 'importTasks', params: { productId: selectedproduct.product_id }
            });
        },
        navigateToImportB2Tasks(selectedproduct) {
            this.$router.push({
                name: 'importTasksB2', params: { productId: selectedproduct.product_id }
            });
        },
        openImportModal() {
            this.showImportModal = true;  // Opens the Import modal
        },
        closeImportModal() {
            this.showImportModal = false;  // Closes the Import modal
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            console.log('File Selected:', file);
            // Handle file upload or store to send on 'Import'
        },
        handleImportResponse(importedTasks) {
            this.tasks = [...this.tasks, ...importedTasks];
            // Extract task ids from the imported tasks data
            const taskIds = importedTasks.map(task => task.id);

            // Fetch and display the tasks
            this.fetchTasks(taskIds);
        },
        async fetchproducts() {
            try {
                const token = localStorage.getItem('token');
                const headers = { Authorization: `Bearer ${token}` };
                const response = await axios.get(`/api/products/${this.productId}`, { headers });
                this.selectedproduct = response.data;
                this.productId = response.data.product_id;
                localStorage.setItem(this.productId);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        },
        async fetchTasks() {
            const token = localStorage.getItem('token');
            try {
                // Fetch tasks by prod_id using API endpoint and authorization token
                const response = await this.$http.get(`/api/tasks/products/${this.productId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                // Assign fetched tasks to local data 'tasks'
                this.tasks = response.data;
                // this.tasks.sort((a, b) => a.task_no - b.task_no);
                console.log(this.tasks);

                this.totalPages = Math.ceil(this.tasks.length / this.perPage);
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        },
        viewEditTask(task_id) {
            localStorage.setItem('task_id', task_id);
            this.showEditTask = true; // Show the modal when the button is clicked
        },
        closeEditTask() {
            this.showEditTask = false; // Close the modal
        },
        onPageChange(page) {
            this.currentPage = page;
            this.calculateTotalPages();
        },
        applyFilters() {
            this.shouldFilter = true;
            this.currentPage = 1; // Reset pagination to the first page
            this.calculateTotalPages();
        },
        clearFilters() {
            this.descriptionFilter = '';
            this.referenceFilter = '';
            this.taskNoFilter = '';
            this.scopeFilter = '';
            this.categoryFilter = '';
            this.manobFilter = '';
            this.esttimeFilter = '';
            this.isExamFilter = '';
            this.chaptFilter = '';
            this.areaFilter = '';
            this.locationFilter = '';
        },
        openExaminableTasksModal() {
            let lastTaskNumber = 0;

            // Filter out non-numeric and undefined/null task numbers before finding the max
            const taskNumbers = this.tasks
                .map(task => task.task_no)
                .filter(taskNo => !isNaN(taskNo) && taskNo !== null && taskNo !== undefined)
                .map(Number);

            if (taskNumbers.length > 0) {
                lastTaskNumber = Math.max(...taskNumbers);
            }

            this.nextTaskNumber = lastTaskNumber + 1;
            this.showExaminableTasksModal = true;
        },
        closeExaminableTasksModal() {
            this.showExaminableTasksModal = false;
        },
        calculateTotalPages() {
            this.computeFilteredTasks();
            this.totalPages = Math.ceil(this.filteredTasksLength / this.perPage);
        },
        computeFilteredTasks() {
            const startIndex = (this.currentPage - 1) * this.perPage;
            const endIndex = startIndex + this.perPage;
            console.log('tasks');   
            console.log(this.tasks);
   
            const filteredTasks = this.tasks.filter((task) => {
                let isDescriptionMatch = !this.descriptionFilter || (task.activity && task.activity.toLowerCase().includes(this.descriptionFilter.toLowerCase()));
                let isReferenceMatch = !this.referenceFilter || (task.reference && task.reference.toLowerCase().includes(this.referenceFilter.toLowerCase()));
                let isTaskNoMatch = !this.taskNoFilter || (task.task_no && task.task_no.toString().toLowerCase().includes(this.taskNoFilter.toLowerCase()));
                let isScopeMatch = !this.scopeFilter || (task.AMTOSS && task.AMTOSS.toLowerCase().includes(this.scopeFilter.toLowerCase()));
                let isCategoryMatch = !this.categoryFilter || (task.category && task.category.toLowerCase().includes(this.categoryFilter.toLowerCase()));
                let ischaptMatch = !this.chaptFilter || (task.chapt_no && task.chapt_no.toString().toLowerCase().includes(this.chaptFilter.toLowerCase()));
                let isAreaMatch = !this.areaFilter || (task.area && task.area.toString().toLowerCase().includes(this.areaFilter.toLowerCase()));
                let islocationMatch = !this.locationFilter || (task.location && task.location.toString().toLowerCase().includes(this.locationFilter.toLowerCase()));

                return isDescriptionMatch && isTaskNoMatch && isReferenceMatch && isScopeMatch && isCategoryMatch && ischaptMatch && isAreaMatch && islocationMatch;
            });
            this.filteredTasksLength = filteredTasks.length
            return filteredTasks.slice(startIndex, endIndex);
        },
    },
    computed: {
        product() {
            return this.$store.state.selectedProduct;
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage;
        },
        endIndex() {
            const end = this.startIndex + this.perPage;
            return end < this.filteredTasksLength ? end : this.filteredTasksLength;
        },
        filteredTasks() {
            return this.computeFilteredTasks();
        },
    },
};
</script>
<style scoped>
.edit-icon {
    font-size: 16px;
    /* Adjust the size as needed */
    color: #615f5f;
    /* Change the color to your preference */
    cursor: pointer;
    transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
}

.edit-icon:hover {
    color: #2b2a2a;
    /* Change the color to your preference */
    cursor: pointer;
    transform: scale(1.1);
    /* Example transform for scaling on hover */

}

.pagination-info {
    font-size: 14px;
    color: #666;
    /* Adjust the color as needed */
    position: absolute;
    top: 305px;
    /* Adjust the top position as needed */
    /* Center horizontally within the container */
    left: 50%;
    /* Center horizontally */
    transform: translateX(-50%);
    /* Center horizontally */
}

.pagination-container {
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>
